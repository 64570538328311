import { getAllUserTransform } from './auth.transform';
import { authActionTypes } from './authActionTypes';

export const updateAuthDataAction = (authData) => ({
  type: authActionTypes.UPDATE_AUTH_DATA,
  payload: authData,
});

export const tokenAuthDataAction = (token) => ({
  type: authActionTypes.LOGIN,
  payload: token,
});

export const clearAuthDataAction = () => ({
  type: authActionTypes.CLEAR_AUTH_DATA,
});

export const loginAuthDataAction =
  (authData) =>
  async (dispatch, _, { AuthService }) => {
    console.log(AuthService);
    try {
      const {
        data: { token },
      } = await AuthService.login(authData);
      dispatch(tokenAuthDataAction(token));
      const { data: userData } = await AuthService.me();
      console.log(userData);
      dispatch(updateAuthDataAction({ userData }));
    } catch (error) {
      console.log(error);
    }
  };

export const getUsersAuthDataAction =
  (authData) =>
  async (dispatch, _, { AuthService }) => {
    console.log(AuthService);
    try {
      const { data } = await AuthService.getUsers(authData);
      const usersAll = getAllUserTransform(data);
      console.log(usersAll);
      dispatch(updateAuthDataAction({ usersAll }));
    } catch (error) {
      console.log(error);
    }
  };
export const changesStatusAuthDataAction =
  (id) =>
  async (dispatch, _, { AuthService }) => {
    console.log(AuthService);
    try {
      const {
        data: { user },
      } = await AuthService.changeStatus(id);
      dispatch({
        type: 'message',
        success: {
          id: 'changed-status',
          close: 3000,
          message: 'Se cambio el estado con exito!!',
          type: 'success',
        },
      });
      dispatch({
        type: authActionTypes.CHANGE_STATUS_USER,
        payload: user,
      });
    } catch (error) {
      console.log(error);
    }
  };
