export const types = {
  login: "[Auth] login",
  logout: "[Auth] logout",
  resetPass: "[Auth] reset",
  token: "[Auth] token",
  accountMessage: "[Auth] accountMessage",

  uiStartLoading: "[UI] Start loading",
  uiFinishLoading: "[UI] Start finish",

  homeRegisterShipping: "[Home Start register Shipping]",

  getInfoNatInt: "[Home Start Register InfoNatInt]",
  getInfoPackageInfo: "[Home Start Register InfoPackageInfo]",
  getInfoCarrierSelect: "[Home Start Register InfoCarrierSelect]",
  getInfoDeclarationAduanas: "Home Start register InfoDeclarationAduanas",

  returnGuide: "PackageInfo Start Change returnGuide",
  changeProformaInvoice: "[RegisterShippment] start changeProformaInvoice",

  getbills: "[account] getbills",

  getShipments: "[shipments] getallshipments",
  getInfoShipmentSelected: "[shipments] getInfoShipmentSelected",
  deleteItemsShipmants: "[shipments] deleteItemsShipmants",
  selectedShipments: "[shipments] selectedShipment",
  editShipment: "[shipments] editShipment",
  addOrder: "[orders] addOrder",
  getAllOrders: "[orders] getAllOrders",

  addNotebook: "[notebook] addNotebook",
  getAllNotebooks: "[notebook] getAllNotebooks",
  editNotebook: "[notebook] editNotebook",
  deleteNotebook: "[notebook] deleteNotebook",
  seledctedNotebook: "[notebook] selectedNotebook",

  addPacking: "[Packing] addPacking",
  getAllPackings: "[Packing] getAllPackings",
  editPacking: "[Packing] editPacking",
  deletePacking: "[Packing] deletePacking",
  selectedPacking: "[Packing] selectedPacking",

  changeStateNotification: "[Notification] changeStateNotification",

  storesConnect: "[Stores] storesConnect",

  getOrdersWoocommerce: "[Stores] getOrdersWoocommerce",
  connectWoocommerce: "[Stores] connectWoocommerce",
  disConnectWoocommerce: "[Stores] disConnectWoocommerce",

  planFree: "[Plans] planFree",

  addQuotation : "[Quote] addQuotation",

  PAYMENTPSE: "PAYMENTPSE",
};
