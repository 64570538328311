
import React from 'react';
import { ChangeCurrencyForm } from '../../forms/charged-currency';
import { UploadZonesForm } from '../../organims/upload-zones-form';
import { UploadCountriesForm } from '../../organims/upload-countries-form';
import { PageTitle} from '../../molecules/page-title';
import { MainConfig } from './style';

export const ConfigPage = () => (
    <MainConfig>
      <PageTitle title='Configuraciones' />
      <p>En configuraciones se podrá ajustar la tasa de cambio que se esta manejando en la plataforma como también adjuntar archivos de excel para el pricing.</p>
      <ChangeCurrencyForm />
      <UploadZonesForm />
      <UploadZonesForm national={false} />
      <UploadCountriesForm  />
      <UploadCountriesForm  national={false} />
    </MainConfig>
);
