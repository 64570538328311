import { toast, Flip } from 'react-toastify';

export const showToast = (props) => {
  let autoClose = 2000;
  if (
    (typeof props.autoClose === 'boolean' && !props.autoClose) ||
    typeof props.autoClose === 'number'
  ) {
    autoClose = props.autoClose;
  }
  const options = {
    autoClose,
    type: props.type || 'default',
    position: props.position || 'bottom-left',
    toastId: props.id,
    className: props.className || undefined,
    transition: props.transition ? Flip : undefined,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    pauseOnFocusLoss: false,
  };
  if (toast.isActive(props.id)) {
    return toast.update(props.id, { ...options, render: props.message });
  }
  return toast(props.message, options);
};
