import React from 'react';
import { PageTitle } from '../../molecules/page-title';
import { MainHome } from './styled';

export const Home = () => (
  <MainHome>
    <PageTitle title='Inicio' />
    <h1>Bienvenida Laura,</h1>
    <p>Este es tu panel administrativo donde podrás hacer la configuración y la supervisión de toda tu operación.</p>
  </MainHome>
);
