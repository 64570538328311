import React from 'react';
import { useDispatch } from 'react-redux';
import { Switch } from 'react-router-dom';

import { PrivateRoute } from '../components/molecules/private-route';
import { PrincipalSideMenu } from '../components/organims/principal-side-menu';
import { MainLayout } from '../components/templates/main-layout';
import { getConfig } from '../store/config/config.actions';
import { privateListRoutes } from './private-route-list';
import { publicListRoutes } from './public-route-list';

export const AppRoutes = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getConfig());
  }, [dispatch]);
  
  return (
    <Switch>
      {publicListRoutes.map((params, index) => (
        <PrivateRoute {...params} key={index} exact />
      ))}
      <MainLayout sideMenu={<PrincipalSideMenu />}>
        {privateListRoutes.map((params, index) => (
          <PrivateRoute {...params} key={index} exact />
        ))}
      </MainLayout>
    </Switch>
  );
};
