import { Button, Modal } from 'antd';
import moment from 'moment'
import { Formik } from 'formik';
import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { changedAmountConfig } from '../../../store/config/config.actions';
// import { getConfigAmountSelector } from '../../../store/config/config.selectors';
import { FormikInput } from '../../molecules/formik-input-field';
import { FormWrapper } from './styles';
import { schemaChardeBalance } from './validation';
import { ApiService } from '../../../services';

export const PickUpForm = ({ record, update }) => {
  const [info, setInfo] = React.useState('');
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [message, setMessage] = React.useState({ statusCode: 200});
  const today = new Date().toISOString().split('T')[0];
  const dateLimit = moment().add(7, 'd').toDate().toISOString().split('T')[0];
  function taskDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    update();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    update();
  };

  const requestPickUpValues = {
    MessageReference: "test-delivery-messagesssssyuej",
    RegionCode: "201480",
    Requestor: {
      AccountType: "D",
      RequestorContact: {
        PersonName: record?.orderInfo?.Shipper?.Contact?.PersonName | 'N/HA',
        Phone: record?.orderInfo?.Shipper?.Contact?.PhoneNumber | 'N/HA',
      }
    },
    Place: {
      LocationType: "B",
      CompanyName: record?.orderInfo?.Shipper?.CompanyName | 'N/HA',
      Address1: record?.orderInfo?.Shipper?.AddressLine1 | 'N/HA',
      PackageLocation: record?.orderInfo?.Shipper?.BuildingName | 'N/HA',
      City: record?.orderInfo?.Shipper?.City | 'N/HA',
      CountryCode: record?.orderInfo?.Shipper?.CountryCode | 'N/HA',
      PostalCode: record?.orderInfo?.Shipper?.PostalCode | 'N/HA',
    },
    Pickup: {
      PickupDate: taskDate(today),
      ReadyByTime: "10:20",
      CloseTime: "14:20",
      Pieces: 1,
      weight: {
        Weight: record?.orderInfo?.ShipmentDetails?.PiecesArray.reduce((a, b) => a + parseInt(b.Weight), 0) | 0,
        WeightUnit: "K"
      }
    },
    PickupContact: {
      PersonName: record?.orderInfo?.Shipper?.Contact?.PersonName | 'N/HA',
      Phone: record?.orderInfo?.Shipper?.Contact?.PhoneNumber | 'N/HA'
    },
    ShipmentDetails: {
      AccountType: "D",
      AWBNumber: record?.guideId | 'N/HA',
      NumberOfPieces: record?.orderInfo?.ShipmentDetails?.PiecesArray.length.toString()  | '0',
      Weight: record?.orderInfo?.ShipmentDetails?.PiecesArray.reduce((a, b) => a + parseInt(b.Weight), 0).toString(),
      WeightUnit: "K",
      GlobalProductCode: "P",
      DoorTo: "DD",
      DimensionUnit: "C",
      Pieces: [{
          Weight: record?.orderInfo?.ShipmentDetails?.PiecesArray[0]?.Weight,
          Width: record?.orderInfo?.ShipmentDetails?.PiecesArray[0]?.Width,
          Height: record?.orderInfo?.ShipmentDetails?.PiecesArray[0]?.Height,
          Depth: record?.orderInfo?.ShipmentDetails?.PiecesArray[0]?.Depth
      }]
    }
  }

  const test1 = async (v, { setSubmitting, setErrors }) => {
    setSubmitting(true);
    try {
        const res = await ApiService.ShipmentsService.bookPickUpShipment(v);
        // const res = { code: 200, message: 'Success', data: { message: 'Su socicitud ha sido aprobada, pasarán por tu mércancía.' }};
        if (res.data.statusCode === 200){
          setInfo(`Exito: La recolección ha sido programada por favor estar pendiente ${res.data?.data?.PickupContact?.PersonName || 'User name'} en el telefono: ${res.data?.data?.PickupContact?.Phone || '3123736141'}`);
          setSubmitting(false);
        }
        console.log(v, res);
    } catch ( err) {
        console.log(v, err);
        setSubmitting(false);
    }
  }

  return (
    <>
    <Button onClick={()=> showModal()}>Pick-up</Button>
    <Modal title='Solicitar Pick-up' visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
    {info === '' ? 
      <Formik
        initialValues={requestPickUpValues}
        // validationSchema={schemaChardeBalance}
        enableReinitialize
        onSubmit={test1}
      >
        {({ isValid, handleSubmit, isSubmitting }) => (
          <FormWrapper onSubmit={handleSubmit}>
            <p>Se esta solicitando la recolección de el envío:  {record.guideId} que incluye {record.orderInfo.ShipmentDetails.PiecesArray.length} unidades.</p>
                <FormikInput InputFullWidth label="Contacto Solicitante" name='Requestor.RequestorContact.PersonName' />
                <FormikInput InputFullWidth label="Celular Solicitante" name="Requestor.RequestorContact.Phone" />
                <FormikInput InputFullWidth label="Nombre de compañía" name="Place.CompanyName" />
                <FormikInput InputFullWidth label="Dirección" name="Place.Address1" />
                <FormikInput InputFullWidth label="Lugar de recolección" name="Place.PackageLocation" />
                <FormikInput InputFullWidth label="Ciudad" name="Place.City" />
                <FormikInput InputFullWidth label="Código postal" name="Place.PostalCode" />
                <FormikInput InputFullWidth type="date" label="Fecha de Recolección" name="Pickup.PickupDate" min={today} max={dateLimit} />

            <div className="submit-button">
              <Button
                type="primary"
                ghost
                onClick={handleSubmit}
                disabled={!isValid || isSubmitting}
                block
              >
                Confirmar
              </Button>
            </div>
          </FormWrapper>
        )}
      </Formik> :
      <p>{info}</p>}
    </Modal>
    </>
  );
};



/* import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from 'moment'
// import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { ApiService } from "../../../services";
import {
  StyledModalBody,
  StyledModalFooter,
  StyledModalHeader,
  StyledButton
} from "./styles";
// import { validations } from "./validations";
// import { ApiService } from "../../../services";
import FormikInput from "../../molecules/formik-input";



export const FormPickUp = ({
  pickUpInfo,
  onToggle,
  update
}) => {
  // const dispatch = useDispatch();
  const [info, setInfo] = useState('');


  return (
    <>
      <Formik


      >
        {({ handleSubmit, values, setFieldValue, isSubmitting }) => (
          <>
            <StyledModalBody>
              {info === '' ?
              <>
                <p>Se esta solicitando la recolección de el envío:  {pickUpInfo.billNumber} que incluye {pickUpInfo.shipment.orderInfo.ShipmentDetails.PiecesArray.length} unidades.</p>
                <FormikInput InputFullWidth label="Contacto Solicitante" name='Requestor.RequestorContact.PersonName' />
                <FormikInput InputFullWidth label="Celular Solicitante" name="Requestor.RequestorContact.Phone" />
                <FormikInput InputFullWidth label="Nombre de compañía" name="Place.CompanyName" />
                <FormikInput InputFullWidth label="Dirección" name="Place.Address1" />
                <FormikInput InputFullWidth label="Lugar de recolección" name="Place.PackageLocation" />
                <FormikInput InputFullWidth label="Ciudad" name="Place.City" />
                <FormikInput InputFullWidth label="Código postal" name="Place.PostalCode" />
                <FormikInput InputFullWidth date label="Fecha de Recolección" name="Pickup.PickupDate" min={today} max={dateLimit} />
              </>
              :
              <p>{info}</p>  
              }
            </StyledModalBody>
            <StyledModalFooter>
              <StyledButton purple outline regular onClick={info === '' ? () => onToggle() : () => { onToggle(); update(); }}>
                Cancelar
              </StyledButton>
              <StyledButton diasble={isSubmitting} purple regular type="button" onClick={info === '' ? () => handleSubmit() : () => { onToggle(); update(); }}>
                {isSubmitting ? 'Cargando...' : 'Aplicar'}
              </StyledButton>

            </StyledModalFooter>
            </>
        )}
      </Formik>
    </>
  );
};

FormPickUp.propTypes = {
  isSender: PropTypes.string,
  onToggle: PropTypes.func.isRequired,
}; */
