import { Layout } from 'antd';
import styled from 'styled-components';

export const StyledBody = styled(Layout.Content)`
  overflow-y: auto;
  background-color: #fff;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 2rem;
  height: 100vh;
  overflow: scroll;
  & > div:first-child {
    padding-top: calc(4 * 8px);
    width: 100%;
  }
`;

export const StyledAside = styled(Layout.Sider)`
  background: #f8f8f8;
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .header-side {
    margin: 20px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
      'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: 600;
    font-style: italic;
  }
  & > .ant-layout-sider-trigger {
    background: #cacaca;
    color: black;
  }
`;

export const StyledFooter = styled(Layout.Footer)`
  background: #f8f8f8;
`;

export const MainContent = styled.div`
  background-color: white;
`;
