import styled from 'styled-components';

export const LoginWrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 1rem;
  height: 100vh;
  align-items: center;

  .login-card {
    display: grid;
    grid-column: 5 / span 4;
    background-color: white;
    border-radius: 1rem;
    padding: 1rem;
  }

  button {
    margin-top: 1rem;
  }
`;
