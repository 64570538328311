import { walletTypes } from './wallet.types';
const INITIAL_STATE = {
  historyAll: null,
  historySelected: null,
};

export const walletReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case walletTypes.GET_ALL_WALLETS:
      return { ...state, historyAll: payload };
    case walletTypes.CLEAN_WALLET:
      return { ...state, historyAll: null };
    default:
      return state;
  }
};
