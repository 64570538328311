import * as yup from 'yup';
import { typesBalance } from '../../../enums/type-balance';

export const schemaChardeBalance = yup.object().shape({
  origin: yup.string(),
  amount: yup.number().positive().required('Campo requerido'),
  type: yup
    .string()
    .oneOf(
      [typesBalance.DEBIT, typesBalance.DEPOSIT],
      'El valor no es un tipo de balance valido'
    )
    .required('Campo requerido'),
});
