export class WalletService {
  constructor(client, pricing, wallet) {
    this.client = client;
    this.pricing = pricing;
    this.wallet = wallet;
  }

  addBudget(body){
    return this.wallet.post('/wallet/cash', body)
  }
  getWallet(){
    return this.wallet.get('/wallet/all-balance')
  } 
  getWalletAllUser() {
    return this.client.get('/wallet');
  }
  getWalletByUser(id) {
    return this.client.get('/wallet/' + id);
  }
  chargedBalance(id, values) {
    return this.client.put(`/wallet/add-balance/${id}`, values);
  }
}
