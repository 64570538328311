export const getAllUserTransform = (values) => {
  const newValues = {};
  values.forEach((value) => {
    newValues[value._id] = value;
  });
  return newValues;
};

export const usersTableTransform = (usersData) => {
  if (!usersData) return null;
  const users = Object.values(usersData)
    .map((user) => {
      return {
        key: user._id,
        _id: user._id,
        name: user.name,
        email: user.email,
        isactive: user.isactive,
        wallet: user.wallet,
        createdAt: user.createdAt,
        updatedAt: user.updatedAt,
      };
    })
    .sort((a, b) => b.isactive - a.isactive);
  return users;
};
