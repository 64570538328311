import React from 'react';
import { ErrorMessage, useField } from 'formik';
import { Input } from 'antd';
import { ErrorText, FieldWrapper } from './styles';

export const FormikInput = ({ label, ...props }) => {
  const [field] = useField({ name: props.name });
  return (
    <FieldWrapper>
      {label && <label>{label}</label>}
      <Input {...field} {...props} />
      <ErrorMessage name={field.name}>
        {(msj) => <ErrorText>{msj}</ErrorText>}
      </ErrorMessage>
    </FieldWrapper>
  );
};
