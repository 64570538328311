import { Button } from 'antd';
import { Formik } from 'formik';
import React from 'react';
import { typesBalance } from '../../../enums/type-balance';
import { FormikInput } from '../../molecules/formik-input-field';
import { FormikSelected } from '../../molecules/formik-selected';
import { FormWrapper } from './styles';
import { schemaChardeBalance } from './validation';

export const ChargedBalanceForm = ({ callback }) => {
  const options = [
    {
      value: typesBalance.DEBIT,
      label: 'Debito',
    },
    {
      value: typesBalance.DEPOSIT,
      label: 'Deposito',
    },
  ];
  return (
    <Formik
      initialValues={{
        origin: 'dashboard',
        amount: '',
        type: '',
      }}
      enableReinitialize
      onSubmit={(values, actions) => {
        callback(values, actions.resetForm);
      }}
      validationSchema={schemaChardeBalance}
    >
      {({ isValid, handleSubmit, submitForm }) => (
        <FormWrapper onSubmit={handleSubmit}>
          <h2>Crear Transaccion</h2>
          <p className="text-info">Valor de la transacción</p>
          <FormikInput
            name="amount"
            style={{ width: '100%' }}
            type="number"
            placeholder="Ej: 123abc456def"
          />
          <FormikSelected
            label="Selecciona un tipo de transferencia"
            name="type"
            options={options}
          />
          <div className="submit-button">
            <Button
              type="primary"
              ghost
              onClick={submitForm}
              disabled={!isValid}
              block
            >
              Confirmar
            </Button>
          </div>
        </FormWrapper>
      )}
    </Formik>
  );
};
