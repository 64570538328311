export class ConfigService {
  constructor(client) {
    this.client = client;
  }

  getConfigService() {
    return this.client.get('/config');
  }
  putChangeAmountService(values) {
    return this.client.put(`/config`, values);
  }
}
