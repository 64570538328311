import { compose } from 'redux';

const rootState = (state) => {
  return state.auth;
};

export const getToken = compose((userState) => {
  return userState.token;
}, rootState);

export const getUsers = compose((userState) => {
  return userState.usersAll;
}, rootState);
