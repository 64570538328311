import { configTypes } from './config.types';
const INITIAL_STATE = {
  amount: null,
};

export const configReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case configTypes.UPDATE_CONFIG:
      return { ...state, ...payload };
    case configTypes.GET_CONFIG:
      return { ...state, ...payload };
    default:
      return state;
  }
};
