export class AuthService {
  constructor(client) {
    this.client = client;
  }

  getUsers() {
    return this.client.get('/auth/get-user');
  }

  updateUser(id, body) {
    return this.client.put(`/users/update-user/${id}`, body);
  }

  me() {
    return this.client.get('/auth/me');
  }
  changeStatus(id) {
    return this.client.get(`/auth/change-status/${id}`);
  }

  login(values) {
    console.log(values);
    return this.client.post('/auth', values);
  }
}
