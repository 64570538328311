import axios from 'axios';
import { getToken } from '../store/auth1/auth.selectors';
import { logout } from '../store/auth/auth.actions';

export const clients = {
  common: axios.create(),
  pricing: axios.create(),
  wallet: axios.create(),
};

export const applyInterceptors = (store) => {
  
  clients.common.interceptors.request.use(async (config) => {
    const newConfig = { ...config };
    const reduxState = store.getState();
    const authToken = getToken(reduxState);

    if (authToken) {
      newConfig.headers.Authorization = 'Bearer ' + authToken;
    }

    return newConfig;
  });

  clients.common.interceptors.response.use(
    async (response) => {
      if (typeof response.data !== 'object') {
        throw Error('Wrong data format from backend');
      }

      return response;
    },
    async function (error) {
      if (error.response.status === 401){
        store.dispatch(logout());
      }
      return Promise.reject(error);
    }
  );

  clients.pricing.interceptors.response.use(
    async (response) => {
      if (typeof response.data !== 'object') {
        throw Error('Wrong data format from backend');
      }

      return response;
    },
    async function (error) {
      if (error.response.status === 401){
        store.dispatch(logout());
      }
      return Promise.reject(error);
    }
  );

  clients.wallet.interceptors.response.use(
    async (response) => {
      if (typeof response.data !== 'object') {
        throw Error('Wrong data format from backend');
      }

      return response;
    },
    async function (error) {
      if (error.response.status === 401){
        store.dispatch(logout());
      }
      return Promise.reject(error);
    }
  );
};
