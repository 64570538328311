import React from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { FormikInput } from '../../molecules/formik-input-field';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { Logo } from '../../atoms/logo';
import logo from '../../../assets/img/shipal-logo.png';
import {  MainLoginForm } from './style';
import { startLoginEmailPassword } from '../../../store/auth/auth.actions';

export const LoginForm = () => {
  const dispatch = useDispatch();
  const schema = {
    email: yup
      .string()
      .email('Debe ser un email válido')
      .required('Campo requerido'),
    password: yup.string().required('Campo requerido'),
  };
  return (
    <Formik
      initialValues={{
        email: 'pandalabco@gmail.com',
        password: 'password1',
      }}
      onSubmit={(data) => {
        dispatch(startLoginEmailPassword(data));
      }}
      validationSchema={yup.object().shape(schema)}
    >
      {({ handleSubmit, submitForm }) => (
        <MainLoginForm onSubmit={handleSubmit} noValidate>
          <div className="header-side">
            <Logo srcUrl={logo} alt="logo-shipal" heigth="44" />
          </div>
          <FormikInput type="email" label="Correo electronico" name="email" />
          <FormikInput type="password" label="Contraseña" name="password" />
          <div>
            <Button onClick={submitForm}>Ingresar</Button>
          </div>
        </MainLoginForm>
      )}
    </Formik>
  );
};
