import { getUsersAuthDataAction } from '../auth1/auth.actions';
import { allHistoryTransform } from './wallet.transform';
import { walletTypes } from './wallet.types';

export const cleanWallet = () => ({
  type: walletTypes.CLEAN_WALLET,
});

export const putChargedBalance =
  (values, id) =>
  async (dispatch, _, { WalletService }) => {
    try {
      await WalletService.chargedBalance(id, values);
      await dispatch(getUsersAuthDataAction());
      dispatch({
        type: 'message',
        success: {
          id: 'charged-balance',
          close: 3000,
          message: 'Se realizo la transacción con exito!!',
          type: 'success',
        },
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: 'message',
        success: {
          id: 'charged-balance',
          close: 3000,
          message: 'ocurrio un error con la transacción',
          type: 'error',
        },
      });
    }
  };

export const getAllWallets =
  () =>
  async (dispatch, _, { WalletService }) => {
    try {
      const {
        data: { history },
      } = await WalletService.getWalletAllUser();
      const dataTransform = allHistoryTransform(history);
      dispatch({
        type: walletTypes.GET_ALL_WALLETS,
        payload: dataTransform,
      });
    } catch (error) {
      console.log(error);
    }
  };

export const getWalletHistoriesOneUser =
  (walletId) =>
  async (dispatch, _, { WalletService }) => {
    try {
      const {
        data: { history },
      } = await WalletService.getWalletByUser(walletId);
      const dataTransform = allHistoryTransform(history);
      dispatch({
        type: walletTypes.GET_ALL_WALLETS,
        payload: dataTransform,
      });
    } catch (error) {
      console.log(error);
    }
  };
