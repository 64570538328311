import { ConfigPage } from '../components/pages/config';
import { Home } from '../components/pages/home';
import { Shipments } from '../components/pages/shipments';
import { TranstacionsPage } from '../components/pages/transactions';
import { UsersPage } from '../components/pages/users';
import { Taxes } from '../components/pages/taxes';
import { UserDetail } from '../components/pages/user-detail';

export const privateListRoutes = [
  {
    path: '/',
    authPage: '/login',
    component: Home,
  },
  {
    path: '/shipments',
    authPage: '/login',
    component: Shipments
  },
  {
    path: '/transactions/:id?',
    authPage: '/login',
    component: TranstacionsPage,
  },
  {
    path: '/taxes',
    authPage: '/login',
    component: Taxes,
  },
  {
    path: '/users',
    authPage: '/login',
    component: UsersPage,
  },
  {
    path: '/users-detail',
    authPage: '/login',
    component: UserDetail,
  },
  {
    path: '/config',
    authPage: '/login',
    component: ConfigPage,
  },
];
