import { configTypes } from './config.types';

export const changedAmountConfig =
  (values, cb) =>
  async (dispatch, _, { ConfigService }) => {
    try {
      const {
        data: { config },
      } = await ConfigService.putChangeAmountService(values);
      await dispatch({
        type: configTypes.UPDATE_CONFIG,
        payload: config,
      });
      dispatch({
        type: 'message',
        success: {
          id: 'changed-amount-config',
          close: 3000,
          message: 'Se realizo la actualización con exito!!',
          type: 'success',
        },
      });
      if (cb) {
        cb();
      }
    } catch (error) {
      dispatch({
        type: 'message',
        success: {
          id: 'changed-amount-config',
          close: 3000,
          message: 'ocurrio un error con la actualización',
          type: 'error',
        },
      });
    }
  };

export const getConfig =
  () =>
  async (dispatch, _, { ConfigService }) => {
    try {
      const {
        data: { config },
      } = await ConfigService.getConfigService();
      dispatch({
        type: configTypes.GET_CONFIG,
        payload: config,
      });
    } catch (error) {
      console.log(error);
    }
  };
