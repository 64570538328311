import {} from 'antd';
import styled from 'styled-components';


export const ContainerLogo = styled.div`

`

export const StyledImage = styled.img`


` 