import React from 'react';
import { Layout, Menu } from 'antd';
import { useDispatch } from 'react-redux';
import { StyledBody, StyledAside, StyledFooter } from './styles';
import { Logo } from "../../atoms/logo";
import logo from '../../../assets/img/shipal-logo.png';
import logoSmall from '../../../assets/img/favicon.ico';

import { 
  UserOutlined
} from '@ant-design/icons';

import { logout } from '../../../store/auth/auth.actions'

export const MainLayout = ({ sideMenu, children }) => {
  const dispatch = useDispatch();
  const [collapsedState, setCollapsedState] = React.useState(false);
  return (
    <Layout style={{ height: '100vh' }}>
      <StyledAside 
        collapsible 
        onCollapse={(collapsed, type) => {
          setCollapsedState(collapsed)
        }}
      >
        <div className="header-side">
          <Logo srcUrl={collapsedState ? logoSmall : logo} alt="logo-shipal" heigth="44" />
        </div>
        {sideMenu}
        <div style={{}} />
        <StyledFooter>
          <Menu style={{}}>
            <Menu.Item onClick={() => dispatch(logout())} key="9" icon={<UserOutlined />}>
                  Cerrar Cesión
            </Menu.Item>
          </Menu>
        </StyledFooter>
      </StyledAside>
      <Layout className="site-layout">
        <StyledBody>{children}</StyledBody>
      </Layout>
    </Layout>
  );
};
