import { Modal } from 'antd';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useDispatch } from 'react-redux';
import { putChargedBalance } from '../../../store/wallet/wallet.actions';
import { ChargedBalanceForm } from '../../forms/charged-balance';

export const ModalBalance = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [id, setId] = useState(null);
  const handleVisible =
    (flag, id = null) =>
    () => {
      setVisible(flag);
      if (id) setId(id);
    };
  useImperativeHandle(
    ref,
    () => ({
      handleVisible,
    }),
    []
  );

  const handleSubmit = async (values, callback) => {
    console.log(values);
    await dispatch(putChargedBalance(values, id));
    callback();
    setId(null);
    setVisible(false);
  };
  return (
    <Modal
      title={props.title}
      visible={visible}
      onOk={null}
      onCancel={handleVisible(false)}
      footer={null}
    >
      <h1>Wallet: {id}</h1>
      <ChargedBalanceForm callback={handleSubmit} />
    </Modal>
  );
});
