import React from 'react';
import { PageTitle } from '../../molecules/page-title';
import { MainTaxes } from './styled';

export const Taxes = () => (
  <MainTaxes>
    <PageTitle title='Impuestos' />
    <h1>Bienvenida Laura,</h1>
    <p>Este es tu panel administrativo donde se gestionarán los impuestos.</p>
  </MainTaxes>
);
