import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router';
import { getToken } from '../../../store/auth/auth.selectors';

export const PrivateRoute = ({ authPage, onAuthRoute, ...props }) => {
  const isAuth = useSelector(getToken);
  const location = props.location;

  if (authPage && !isAuth) {
    return (
      <Redirect
        to={{
          pathname: authPage,
          state: { redirectFrom: location?.pathname },
        }}
      />
    );
  }

  if (onAuthRoute && isAuth) {
    return (
      <Redirect
        to={{
          pathname: onAuthRoute,
          state: { redirectFrom: undefined },
        }}
      />
    );
  }

  return <Route {...props} />;
};
