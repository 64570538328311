import React from 'react';
import PropTypes from 'prop-types';
import { StyledImage } from "./styles";

export const Logo = ({ srcUrl, alt, heigth, ...props  }) => {
    return (
        <StyledImage src={srcUrl} alt={alt} {...props} height={heigth}/>
    )
}

Logo.propTypes = {
    srcUrl: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    roundedCircle: PropTypes.bool,
    thumbnail: PropTypes.bool,
    heigth: PropTypes.string,
}
