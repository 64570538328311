import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { authReducer } from './auth/auth.reducers';
import { walletReducer } from './wallet/wallet.reducer';
import { configReducer } from './config/config.reducer';

export const AppReducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    config: configReducer,
    wallet: walletReducer,
  });
