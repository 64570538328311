import styled from 'styled-components';

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  padding: 0.5rem;

  & > h2 {
    text-align: center;
  }

  .text-info {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .date-wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5rem;
  }

  .submit-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0.5rem 0px;
  }
`;
