import { types } from "../types";
// Funciones viejas:
/* 
  1.startPlanFree:  (No se lo que hace.)
    {
      type: types.planFree,
      payload: false,
    }
  2.startSelectedPlanUser:  form-add-credit-card component. ePayco stuff
    const { idDoc } = getState().auth;
    await selectedPlanUser(idDoc, plan);
  3.changeIsActiveService: Setting-providers ads or deletes idDoc on my providers list.. 
    const { idDoc } = getState().auth;
    await addServiceDisabled(idDoc, method, idDocCarrier);
    dispatch({
      type: "auth_update_change_service",
    });
  4. changeNotificationState: update my notifications toggle button.
    await starChangeStateNotification(idDoc, data);
      dispatch({
        type: types.changeStateNotification,
        payload: data,
      }
  5.deleleImgProfile => Update user.
    export const deleleImgProfile = () => ({
      type: types.deleteImgPorfile,
    });
  6. changebalanceAvailable: => Update user.
  7. startChangeInfoUser: => Update user.
  8. startGetUserInfo: => Get Me
  9. startChangePassword || RecoverPasswordEmail => cambiarContrasena
  10.startRegisterWithEmailPasswordData => RegisterUser
*/

// Loading user state data.

// Login user
export const startLoginEmailPassword = ({email, password}) => {
    return async (dispatch, _,  { AuthService }) => {
      try {
        const res = await AuthService.login({ email, password });
        if (res.status === 401) {
          dispatch({
            type: types.accountMessage,
            payload: res.data.message,
          });
        }
        if (res.data.code === 404) {
          dispatch({
            type: types.accountMessage,
            payload: 'Ops: Hubo un error. Vuélvelo a intentar revisando que escribiste bien el correo y la contraseña.',
          });
        }
        if (res.status === 200) {
            dispatch({
              type: types.token,
              payload: res.data.data.token,
            });
        }
      } catch (error) {
        if (error.response.status !== 200) {
          dispatch({
            type: types.accountMessage,
            payload: error.response.data.message,
          });
        } else if (error.response.status === 500) {
          dispatch({
            type: types.accountMessage,
            payload: error.response.data.message,
          });
        };
      }
    };
  };

export const login = (dataUser) => ({
    type: types.login,
    payload: dataUser,
    success: {
      id: "login",
      message: "Inicio sesion con exito",
    },
  });

// Register User
export const registerUser = (infoUser) => async (dispatch, _, { AuthService }) => {
    try {
      const res =  await AuthService.register(infoUser);
      console.log('res', res);
      if (res.status === 401) {
        dispatch({
          type: types.accountMessage,
          payload: res.data.message,
        });
      }
      if (res.data.code === 404) {
        dispatch({
          type: types.accountMessage,
          payload: res.data.message,
        });
      }
      if (res.status === 200) {
        dispatch({
          type: types.token,
          payload: res.data.token,
        });
      }

    } catch (error) {
      if (error.response.status !== 200) {
        dispatch({
          type: types.accountMessage,
          payload: error.response.data.message,
        });
      } else if (error.response.status === 500) {
        dispatch({
          type: types.accountMessage,
          payload: error.response.data.message,
        });
      };
      throw error;
    }
  };

// Get User Info
export const getUserInfo = () => {
    return async (dispatch, _, { AuthService }) => {
      try {
        const { data } = await AuthService.me();
        dispatch(login(data.data));
      } catch (error) {
        console.log(error);
      }
    };
  };

// Log Out
export const logout = () => ({
    type: types.logout,
  });



