import styled from 'styled-components';

export const ErrorText = styled.span`
  color: red;
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
