import { Menu } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { menuOptions } from './options-menu';
import { push } from 'connected-react-router';

export const PrincipalSideMenu = () => {
  const dispatch = useDispatch();
  const pushHistory = (route) => () => {
    dispatch(push(route));
  };
  return (
    <Menu style={{}}>
      {menuOptions.map((item, index) => (
        <Menu.Item
          key={index}
          onClick={pushHistory(item.route)}
          icon={item.icon}
        >
          {item.name}
        </Menu.Item>
      ))}
    </Menu>
  );
};
