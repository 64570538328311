import styled from 'styled-components';

export const MainHome = styled.div`
    display: flex;
    flex-direction: column !important;
`;

export const MainUserInfo = styled.div`
    display: flex;
    flex-direction: row !important;
    width: 100%;
    flex-wrap: wrap;
    & > p {
        width: 50%;
        height: 15px;
    }
    & > p:nth-child(even) {
        text-align: right;
        font-weight: 800;
    }
    & > p:nth-child(odd) {
        text-align: left;
    }
`;

export const MainUserInfoInner = styled.div`
    display: flex;
    flex-direction: row !important;
`;
