import React from 'react';
import { LoginForm } from '../../forms/loginForm';
import { LoginWrapper } from './styles';

export const Login = () => {
  return (
    <LoginWrapper>
      <div className="login-card">
        <LoginForm />
      </div>
    </LoginWrapper>
  );
};
