export class UsersService {
  constructor(client) {
    this.client = client;
  }

  getUsers(page, limit) {
    return this.client.get(`/users?page=${page}&limit=${limit}`);
  }

  putActivateArrayOfUsers(values) {
    return this.client.put(`/users/activate`, values);
  }
}
