import { createBrowserHistory } from 'history';
import storage from 'redux-persist/lib/storage';
import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { AppReducers } from './reducers';
import { AppMiddlewares } from './middlewares';

export const history = createBrowserHistory();

const persistConfig = {
  key: 'shipal-admin-v1.0.0',
  storage,
  whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, AppReducers(history));
const store = createStore(persistedReducer, AppMiddlewares(history));

export const AppStore = store;

export const persistor = persistStore(store);
