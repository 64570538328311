import React from 'react';
import { useEffect } from 'react';
import { ApiService } from '../../../services';
import { useDispatch } from 'react-redux';  
import { PageTitle } from '../../molecules/page-title';
import { Spin } from 'antd'
import { Table } from 'antd';

export const TranstacionsPage = () => {
  const dispatch = useDispatch();
  const [ envios, setEnvios ] = React.useState()
  useEffect(() => {
    dispatch({
      type: 'test',
      success: {
        id: 'custom',
      },
    });
    test()
  }, [dispatch]);
  const test = async () => {
    try {
      const { data } = await ApiService.WalletService.getWallet();
      console.log(data.motionHistory);
      setEnvios(data.map((trans, index) => ({
        key: index,
        user: trans.user,
        monto: trans.balance,
        motionHistory: trans.motionHistory
      })));
    } catch (error) {
      console.log(error);
    }
  };
  
  if(!envios) return <div style={{ margin: 'auto 50%'}}><Spin size="Large" /></div>;
  const expandedRowRender = (data) => {
    const ordered = data.motionHistory.sort(function(a,b){
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.date)-new Date(a.date);
    });
    console.log("🚀 ~ file: index.jsx ~ line 43 ~ ordered ~ ordered", ordered)
    const columns = [
      { title: 'Date', dataIndex: 'date', key: 'date', render: (text) => <p>{new Date(text).toLocaleString()}</p>, },
      { title: 'Tipo', dataIndex: 'type', key: 'type', render: (text) => <p>{text === 'CRED' ? 'Abono' : 'Débito'}</p>, },
      { title: 'Monto', dataIndex: 'balance', key: 'balance', render: (text) => <p>{`${text.toFixed(2)} COP`}</p>  },
      { title: 'Origen', dataIndex: 'bank', key: 'bank' },
    ];
    return <Table columns={columns} dataSource={ordered || []} pagination={false} />;
  };

  const columns = [
    { title: 'Name', dataIndex: 'user', key: 'user', render: (text) => <p>{text.firstName} {text.lastName}</p> },
    { title: 'Balance', dataIndex: 'monto', key: 'monto', render: (text) => <p>{`${text.toFixed(2)} COP`}</p> }
  ];

  return (
    <div>
    <PageTitle title={'Transacciones'} />
    <p>En transacciones vamos a poder administrar y analizar acciones con nuestra billetera. Consolidar pagos y debitos en las billeteras internas.</p>
    <Table
      className="components-table-demo-nested"
      columns={columns}
      expandable={{ expandedRowRender: record => expandedRowRender(record) }}
      dataSource={envios}
    />
    </div>
  );
}