import styled from 'styled-components';

export const MainLoginForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .header-side {
        margin-bottom: 1em;
    }
`;