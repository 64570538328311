
import { 
  UserOutlined,
  LaptopOutlined,
  NotificationOutlined,
  BoxPlotFilled,
  MoneyCollectOutlined
} from '@ant-design/icons';
export const menuOptions = [
  {
    name: 'Inicio',
    route: '/',
    icon: <LaptopOutlined />
  },
  {
    name: 'Envios',
    route: '/shipments',
    icon: <BoxPlotFilled />
  },
  {
    name: 'Transacciones',
    route: '/transactions',
    icon: <MoneyCollectOutlined />
  },
/*   {
    name: 'Taxes',
    route: '/taxes',
    icon: <UserOutlined />
  }, */
  {
    name: 'Usuarios',
    route: '/users',
    icon: <UserOutlined />
  },
  {
    name: 'Configuraciones de plataforma',
    route: '/config',
    icon: <NotificationOutlined />
  },
];
