import { compose } from 'redux';

// Auth State
const rootState = (state) => {
  return state.auth;
};

// Get Token for API AUTH Calls
export const getToken = compose((userState) => {
  return userState.token;
}, rootState);

// Is User Authenticated
export const getAuth = compose((userState) => {
  return userState.authenticated;
}, rootState);

// Get User
export const getUserData = compose((userState) => {
  return userState.userData;
}, rootState);

// Get Wallet
export const getWallet = compose((userState) => {
  return userState.wallet;
}, rootState);
