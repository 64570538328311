// Imports
import React from 'react'
import { StyledTitleDiv,  StyledHr } from './style';
export const PageTitle = ({ title }) => {
    return (
    <StyledTitleDiv>
        <h3 className="my-3 font-weight-bold">
            {title}
        </h3>
        < StyledHr/>
    </StyledTitleDiv>
    )
};
