import styled from 'styled-components';

export const FormWrapper = styled.form`
  display: flex;

  flex-direction: column;
  margin-bottom: 1rem;
  padding: 0.5rem;

  & > h2 {
    text-align: center;
  }

  .text-info {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .date-wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5rem;
  }

  .submit-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0.5rem 0px;
  }

  @media (min-width: 960px) {
    width: 50%;
  }
`;

export const FormInput = styled.label`
  line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    user-select: none;
    touch-action: manipulation;
    height: 32px;
    padding: 4px 15px;
    font-size: 14px;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.65);
    border-color: #d9d9d9;
    background: #fff;
    & > input[type="file"] {
        display: none;
    }
`;